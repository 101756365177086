<template>
	<!--规则详情-->
	<mainBox>
		<el-form class='ruleConfigForm' label-width="108px" ref='htmlFrom' :model="htmlDatas" :rules="formRules" :disabled='isLook'>
			<el-row>
			  <el-col :span="6">
				  <el-form-item label="规则名称"  prop="ruleName">
				  	<el-input v-model="htmlDatas.ruleName" placeholder='请输入' maxlength='20'></el-input>
				  </el-form-item>
			  </el-col>
			  <el-col :span="8">
				  <el-form-item label="规则说明">
					<el-input v-model="htmlDatas.description"  placeholder='请输入' maxlength='150'></el-input>
				  </el-form-item>
			  </el-col>
			</el-row>
			<el-form-item label="规则配置">
				<div class="txTip"><i class="el-icon-warning"></i>用户自由配置的规则条件和执行操作，规则生成后开始根据规则配置计算告警</div>
			</el-form-item>
			<el-form-item label="生效时间" >
				<el-switch class='effectTime' v-model="htmlDatas.isEffect"  active-color="#409EFF" inactive-color="#ccc"> </el-switch>
				<template v-if="htmlDatas.isEffect" >
					<el-form-item class='inline' prop="activeTime">
						<el-checkbox-group class='checkboxWarp' v-model="htmlDatas.activeTime" size="small" >
					      	<el-checkbox-button v-for='(it,dex) in weeksArr' :key='dex'  :label="it.val">{{it.name}}</el-checkbox-button>
					    </el-checkbox-group>
					</el-form-item>
					<el-form-item class='inline' prop="startEndTime">
						
						<el-time-picker  v-model="htmlDatas.startTime" placeholder="起始时间" :picker-options='timePros' size='small' value-format="HH:mm:ss" format="HH:mm:ss"></el-time-picker>
					  	<span>-</span>
					  	<el-time-picker v-model="htmlDatas.endTime" placeholder="结束时间" :picker-options='timePros' size='small' value-format="HH:mm:ss" format="HH:mm:ss"></el-time-picker>
						
					<!--<el-time-picker class='mlft12 pcikTime' size='small' is-range v-model="htmlDatas.startEndTime" value-format="HH:mm:ss"  format="HH:mm:ss"
						range-separator="至"
					    start-placeholder="开始时间"
					    end-placeholder="结束时间"
					    placeholder="选择时间范围">
					</el-time-picker>-->
					</el-form-item>
				</template>
			</el-form-item>
			<el-form-item label="当以下">
				<el-radio-group v-model="htmlDatas.logic" size="small" >
					<el-radio-button v-for='(it,dex) in conditionTypes' :key='dex'  :label="it.val">{{it.name}}</el-radio-button>
				</el-radio-group>
				被满足时
			</el-form-item>
			<el-form-item  v-for='(it,dex) in conditionArrs' :key='dex' :label="(dex<1?'当条件':'条件')+(dex+1)">
				<!--条件1234-->
				<el-select ref='diySelect' v-model="it.keys" style="width: 200px;" @change='it.val=null'>
					<el-option  v-for="ops in conditOptions" :key='ops.keys' :label="ops.name" :value='ops.keys'></el-option>
				</el-select>
				<template v-if='it.keys=="positionId"||it.keys=="categoryId"'>
					<div class="treeWarp">
						<tree-select @open='listenTree' v-model="it.val" :disabled='isLook' :miltiple="false" :show-count="true" placeholder="请选择" :options="checkOptions[it.keys]" @select="treeSelect(it,arguments)" />
					</div>
				</template>
				<template v-else>
					<el-select v-model="it.val" ref='diySelect' class="treeWarp" filterable filterable>
						<el-option  v-for="ops in checkOptions[it.keys]" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
					</el-select>
				</template>
				<template v-if="!isLook">
					<el-button type="primary" v-if='dex<1' size="small" plain @click='addConditionOptions'>添加条件</el-button>
					<el-button  type="danger" v-if='dex>0' size="small" plain @click='conditionArrs.splice(dex,1)'>删除条件</el-button>
				</template>
			</el-form-item>
			<el-form-item label="则执行动作">
				<el-radio-group v-model="htmlDatas.type" size="small" @change='actionTypeChange'>
					<el-radio-button v-for='(it,dex) in actionArrs' :key='dex'  :label="it.val">{{it.name}}</el-radio-button>
				</el-radio-group>
				被满足时
			</el-form-item>
				<el-form-item label="" prop="actionRule">
					<div>
						<el-radio class='diyRadio' v-model="htmlDatas.actionCondition" label="1" >
						在<el-input class='minInp' v-model="htmlDatas.alarmTime" placeholder='请输入' size="mini" clearable oninput="value=value?value.replace(/^(0+)|[^\d]+/g,''):1" :disabled='htmlDatas.actionCondition!=="1"'></el-input>分钟内，
						设备告警次数达到<el-input class='minInp' v-model="htmlDatas.alarmCount" placeholder='请输入' clearable size="mini" oninput="value=value?value.replace(/^(0+)|[^\d]+/g,''):1" :disabled='htmlDatas.actionCondition!=="1"'></el-input>次，
						告警级别为
						<el-select v-model="htmlDatas.alarmLevel" size="mini" class='minInp' :disabled='htmlDatas.actionCondition!=="1"' filterable>
							<el-option  v-for="ops in lvAlarmOptions" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
						</el-select>
						</el-radio>
					</div>
					<div>
						<el-radio class='diyRadio' v-model="htmlDatas.actionCondition" label="2" >
							告警
							<!--<el-input class='minInp' v-model="htmlDatas.normalTime" size="mini" :disabled='htmlDatas.actionCondition!=="2"'  placeholder='请输入' ></el-input>-->
							<el-autocomplete class="minInp" clearable v-model="htmlDatas.normalTime" size="mini" oninput="value=value?value.replace(/^(0+)|[^\d]+/g,''):1" :disabled='htmlDatas.actionCondition!=="2"' :fetch-suggestions="querySearch" placeholder="请输入"></el-autocomplete>
							分钟内未恢复；
						</el-radio>
					</div>
				</el-form-item>
				<template v-if="htmlDatas.type=='alarmNotification'">
					<el-form-item label="通知接收人"  prop='receiveMethod'>
						<el-checkbox v-model="htmlDatas.devicePrincipal" true-label='1' false-label='0'>设备负责人</el-checkbox>
						<el-checkbox v-model="htmlDatas.deviceDepartmentPrincipal" true-label='1' false-label='0'>设备部门负责人</el-checkbox>
						<el-checkbox v-model="htmlDatas.deviceDutyDepartment" true-label='1' false-label='0'>设备责任部门</el-checkbox>
						<el-checkbox v-model="htmlDatas.webIsAssigner" true-label='1' false-label='0'>指定人</el-checkbox>
						<el-select v-if='htmlDatas.webIsAssigner>0' class='appointInp' v-model="htmlDatas.mulAssigner" multiple collapse-tags filterable>
							<el-option  v-for="ops in peopleOptions" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
						</el-select>
					</el-form-item>
				</template>
				<template v-else-if="htmlDatas.type=='generateInspection'">
					<el-form-item label="选择巡检计划" prop='inspectionPlanId'>
						<el-select v-model="htmlDatas.inspectionPlanId" style="width: 200px;" filterable>
							<el-option  v-for="(ops,dex) in insPlanOption" :key='dex' :label="ops.name" :value='ops.val'></el-option>
						</el-select>
					</el-form-item>
				</template>
				<template v-else>
					<el-form-item label="工单接收人" prop='receivePeople'>
						<el-radio-group v-model="htmlDatas.receiveType" style='width: 100%;'>
						<el-row>
								<el-col :span="10" v-for='(value,keys) in receiveOptions' :key='keys'>
							     	<el-radio  class='r_radios' :id="keys" :label="keys" @click.native.prevent="changeRadio(keys)">{{value.name}}</el-radio>
							 	 	<el-select  v-model="htmlDatas[keys]" :disabled='htmlDatas.receiveType!==keys' clearable filterable>
										<el-option  v-for="ops in value.options" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
									</el-select>
						 	 	</el-col>
					 	 	
					 	 	<!--<el-col :span="12">
						 	 	<el-radio v-model="htmlDatas.receiveType" label="specificDepartment" >指定部门</el-radio>
						 	 	<el-select v-model="htmlDatas.specificDepartment" :disabled='htmlDatas.receiveType=="assigner"'>
									<el-option  v-for="ops in deptOptions" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
								</el-select>
					 	 	</el-col>
					 	 	<el-col :span="12">
						 	 	<el-radio v-model="htmlDatas.receiveType" label="assigner" >指定人</el-radio>
						 	 	<el-select v-model="htmlDatas.assigner"   :disabled='htmlDatas.receiveType=="specificDepartment"'>
									<el-option  v-for="ops in peopleOptions" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
								</el-select>
					 	 	</el-col>-->
						</el-row>
						
						</el-radio-group>
					</el-form-item>
					<el-form-item label="工单级别" prop='orderLevel'>
						<el-select v-model="htmlDatas.orderLevel" style="width: 200px;" filterable>
							<el-option  v-for="ops in levelOptions" :key='ops.keys' :label="ops.name" :value='ops.val'></el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item label="通知方式" prop='notiMethod' v-show="htmlDatas.type!=='generateInspection'">
					<el-checkbox v-model="htmlDatas.officialAccount" true-label='Y' false-label='N'>公众号</el-checkbox>
					<el-checkbox v-model="htmlDatas.textMessage" true-label='Y' false-label='N'>短信</el-checkbox>
				</el-form-item>
		</el-form>
		<div class="btmBtn" >
    		<el-button class="searchbtn" @click='cancelSubmit'> 取消</el-button>
    		<el-button type="primary" v-if='!isLook' :disabled='isHttping' @click='validateFroms'> 提交</el-button>
        </div>
	</mainBox>

</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import TagBind from "@/components/TagBind";
	import { listToTree } from "@/utils/tree";
	import TreeSelect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import {getPosition} from "@/api/business/order/tenant/order";
	import { getCategoryList } from "@/api/business/base/tenant/category";
	export default {
		props: {},
		components: {TForm,TagBind,TreeSelect},
		data() {
			return {
				isLook:false,//是否查看，查看的话不允许编辑
				dataId:'',
				htmlDatas: {},
				timePros:{selectableRange: '00:00:00 - 23:59:59'},
				formRules:{
					ruleName: [{ required: true, message: '请输入', trigger: 'blur' }],
//					activeTime: [{validator: this.checkIsTimeActive,trigger: 'change'}],
					activeTime: [{required: true, message: '请选择',trigger: 'change'}],
					startEndTime: [{validator: this.checkStartEnd,trigger: 'change'}],
					notiMethod: [{validator: this.checkNotiMethod,trigger: 'change'}],
					orderLevel: [{ required: true, message: '请输入', trigger: 'blur' }],
					inspectionPlanId: [{ message: '请输入',validator: this.checkinspPlanId, trigger: 'change' }],
					receiveMethod:[{validator: this.checkReceiveMethod,trigger: 'change'}],
					receivePeople:[{validator: this.checkReceivePeople,trigger: 'change'}],
					actionRule:[{validator: this.checkActionRule,trigger: 'change'}],
				},
				receiveOptions:{
					specificDepartment:{name:'指定部门',bindKey:'specificDepartment',options:[]},
					assigner:{name:'指定人',bindKey:'assigner',options:[]}
				},
				conditOptions:[
					{name:'项目',val:'',keys:'projectId'},
					{name:'位置',val:'',keys:'positionId'},
					{name:'系统',val:'',keys:'categoryId'},
					{name:'设备',val:'',keys:'deviceId'},
				],
				checkOptions:{
					projectId:[],
					positionId:[],
					categoryId:[],
					deviceId:[],
				},
				conditionArrs:[
					{keys:'',val:null},
				],
				weeksArr:[
					{name:'星期天',val:'sun'},
					{name:'星期一',val:'mon'},
					{name:'星期二',val:'tue'},
					{name:'星期三',val:'wed'},
					{name:'星期四',val:'thu'},
					{name:'星期五',val:'fri'},
					{name:'星期六',val:'sat'},
				],
				conditionTypes:[
					{name:'条件之一',val:'OR'},
					{name:'所有条件',val:'AND'},
				],
				actionArrs:[
					{name:'告警通知',val:'alarmNotification'},
					{name:'生成工单',val:'generateOrder'},
					{name:'生成保养',val:'generateInspection'},
				],
				lvAlarmOptions:[
					{name: "全部",val: "all"},
					{name: "提示",val: "info"},
					{name: "一般",val: "warn",},
					{name: "严重",val: "error",},
				],
				deptOptions:[],
				peopleOptions:[],
				levelOptions:[
					{name:'普通',val:'0'},
					{name:'紧急',val:'1'},
					{name:'告警',val:'2'},
				],
				insPlanOption:[],
				isHttping:false,
				radioKey: false,

			}
		},

		computed: {},
		watch: {
//			dataId: {
//				deep: true, // 深度监听
//				handler(val, oval) {
//					console.log('dataId', val);
//				}
//			},
		},
		created() {
			this.resetTFrom();
			this.initHtml();
			let {query}=this.$route;
			if(query.isLook){
				this.isLook=true;
			}
			if(query&&query.dataId){
				this.dataId=+query.dataId;
				this.getDatasById();
			}
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeRadio(val) {
			  	this.htmlDatas.receiveType= this.htmlDatas.receiveType== val ? '' : val
			},
			resetTFrom() {
				let sTime=this.common.getTimeMmss(new Date(),'hms');
				this.htmlDatas={
					ruleName:'',
					ruleStatus:"ENABLE",
					description:'',
					isEffect:false,
					activeTime:[],
					startEndTime: ['', ''],
					startTime:'',
					endTime:'',
					logic:'AND',
					type:'alarmNotification',
					actionCondition:'1',// 动作触发条件: "1"代表告警次数, "2"代表未恢复
					alarmCount: null,//校验告警次数的次数
					alarmLevel: null,//校验告警次数的告警级别
					inspectionPlanId:null,//选择巡检计划
					alarmTime: null,//校验告警未恢复的时间,单位分钟
					normalTime:null,//Int 分钟
					officialAccount:'N',
					textMessage:'N',
					orderLevel:'',//工单级别
					receiveType:'',//工单接收人类型  specificDepartment=指定部门 assigner 指定人
					specificDepartment:'',//指定部门
					
					devicePrincipal:'0', //设备负责人
					deviceDepartmentPrincipal:'0', //设备部门负责人
					deviceDutyDepartment:'0', // 设备责任部门
					webIsAssigner:'0',//前端用于指定人是否勾选
					mulAssigner:[],//通知接收人-指定人 多选
					assigner:[],//工单接收人-指定人
				}
			},
			initHtml(){
				this.initInspectionPlanList();
				this.initProjectArrs();
				this.getPosition();
				this.getCategoryList();
				this.initDeviceArrs();
				this.initDepartment();
				this.initPeopleList();
				
			},
			actionTypeChange(){
//				if(this.htmlDatas.type=='generateOrder'){
//					this.$nextTick(()=>{
//						this.listenClick();
//					})
//				}
			},
			treeSelect(it,vals){},
			async checkIsTimeActive(rule, value, callback){
				if(this.htmlDatas.isEffect){
					if(this.htmlDatas.activeTime.length<1){
						callback(new Error('请选择时间'));
					}else{
						callback();
					}
				}else{
					callback();
				}
			},
			checkStartEnd(rule, value, callback){
				if(this.htmlDatas.type=='generateInspection'){
					callback();
					return;
				}
				console.log(value)
				let isAllow=true;
				if(!this.htmlDatas.startTime||!this.htmlDatas.endTime){
					isAllow=false;
				}
//				value.map(it=>{
//					if(!it){
//						isAllow=false;
//					}
//				})
				if(isAllow){
					callback();
				}else{
					callback(new Error('请选择时间'));
				}
			},
			checkNotiMethod(rule, value, callback){
				if(this.htmlDatas.type=='generateInspection'){
					callback();
					return;
				}
				if(this.htmlDatas.officialAccount=='N'&&this.htmlDatas.textMessage=='N'){
					callback(new Error('请选择'));
				}else{
					callback();
				}
			},
			checkinspPlanId(rule, value, callback){
				if(this.htmlDatas.type!=='generateInspection'){
					callback();
				}else{
					if(value){
						callback();
					}else{
						callback(new Error('请选择'));
					}
					
				}
				
			},
			checkReceiveMethod(rule, value, callback){
				if(this.htmlDatas.type=='generateInspection'){
					callback();
					return;
				}
				let isAllow=false;
				let keyArr=['devicePrincipal','deviceDepartmentPrincipal','deviceDutyDepartment','webIsAssigner'];
				for(let keys of keyArr){
					if(this.htmlDatas[keys]>0){
						isAllow=true;
					}
				}
				if(!isAllow){
					callback(new Error('请正确选择'));
				}else{
					if(this.htmlDatas.webIsAssigner>0){
						if(this.htmlDatas.mulAssigner.length<1){
							callback(new Error('请选择指定人'));
						}else{
							callback();
						}
					}else{
						callback();
					}
				}
			},
			checkActionRule(rule, value, callback){
				console.log('5555',value)
				if(this.htmlDatas.type=='generateInspection'){
					callback();
					return;
				}
				if(this.htmlDatas.actionCondition == 1){
					if(!this.htmlDatas.alarmTime||!this.htmlDatas.alarmCount||this.htmlDatas.alarmLevel===null){
						callback(new Error('请输入'));
					}else{
						callback();
					}
				}else{
					if(!this.htmlDatas.normalTime){
						callback(new Error('请输入'));
					}else{
						callback();
					}
				}
				
				
			},
			checkReceivePeople(rule, value, callback){
				if(this.htmlDatas.type=='generateInspection'){
					callback();
					return;
				}
				let keys=this.htmlDatas.receiveType;
				if(keys){
					if(this.htmlDatas[keys]){
						//有选择指定部门或者指定人
						callback();
					}else{
						callback(new Error('请选择'));
					}
				}else{
					callback();
				}
			},
			async getDatasById() {
				if (this.dataId) {
					let res = await this.ApiHttp('/order/rule/details?ruleId=' + this.dataId);
					if (res) {
						this.htmlDatas.ruleKey=res.ruleKey;
						this.htmlDatas.ruleStatus=res.ruleStatus;
						if(res.ruleConfig) res.ruleConfig=JSON.parse(res.ruleConfig);
						//因后台要的数据结构与前端差异很大  需强转
						console.log(99999999,JSON.parse(JSON.stringify(res)))
						for(let keys in this.htmlDatas){
							if(res[keys]){
								this.htmlDatas[keys]=res[keys];
							}
						}
						const {actions,conditionGroup}=res.ruleConfig;
						if(conditionGroup.activeTime){
							//转生效时间  后台的逻辑 有值代表选了生效时间
							this.htmlDatas.activeTime=[]
							for(let keys in conditionGroup.activeTime){
								//转换时间
								if(keys){
									this.htmlDatas.isEffect=true;
								}
								this.htmlDatas.activeTime.push(keys); //获取星期几
								//this.htmlDatas.startEndTime=conditionGroup.activeTime[keys];//获取时间段的时间值
								let len=conditionGroup.activeTime[keys].length;
								if(len>1){
									this.htmlDatas.startTime=conditionGroup.activeTime[keys][0];
									this.htmlDatas.endTime=conditionGroup.activeTime[keys][1];
								}
							}
						}
						if(conditionGroup.logic){
							//条件之一 或者所有条件
							this.htmlDatas.logic=conditionGroup.logic;
						}
						conditionGroup.conditions=conditionGroup.conditions||[];
						this.conditionArrs=[];
						conditionGroup.conditions.map((it,dex)=>{
							//转换条件1234
							if(it.condition){
								let keyArrs=Object.keys(this.checkOptions)
								for(let key1 in it.condition){
									if(keyArrs.indexOf(key1)>-1){
										this.conditionArrs.push({'keys':key1,'val':it.condition[key1]});
									}
	//								this.conditionArrs[dex].keys=key1;
	//								this.conditionArrs[dex].val=it[key1];
								}
							}
						})
						if(actions){
							actions.map(item=>{
								if(item.type){
									this.htmlDatas.type=item.type;
									let dataObj=item[item.type];
									for(let key2 in dataObj){
										if(key2=='notificationRecipient'){
											const notiData=dataObj[key2]||{};
											for(let key7 in notiData){
												if(key7=='assigner'){
													if(notiData[key7]){
														this.htmlDatas.webIsAssigner='1';//当assigner选了指定人的时候 前端就要勾选  并且赋值指定人
														this.htmlDatas.mulAssigner=notiData[key7].split(';');
														//吐了 xxxxxx
													}else{
														this.htmlDatas.mulAssigner=[]
													}
												}else{
													this.htmlDatas[key7]=notiData[key7];
												}
											}
										}
										else if(key2=='orderRecipient'){
											//==generateOrder执行动作为生成工单时
											dataObj[key2]=dataObj[key2]||{};
											for(let key8 in dataObj[key2]){
												this.htmlDatas.receiveType=key8;
												this.htmlDatas[key8]=dataObj[key2][key8]; //工单接收人指定人是单选  通知接收人 指定人是多选
												//specificDepartment代表工单接收人选的是指定部门 单选 assigner代表工单接收人选的是指定人
											}
										}
										else{
											this.htmlDatas[key2]=String(dataObj[key2]);
											
										}
									}
								}
							})
						}
					}
				} else {
					//没有ID 代表新增，重置输入框
					this.resetTFrom()
				}
			},
			
			listenClick(){
				let els=window.document.getElementsByClassName('r_radios');
				for(let it of els){
					it.onclick=()=>{
						
						if(it.id==this.htmlDatas.receiveType){
							this.htmlDatas.receiveType='';
							let actArrs=it.getElementsByClassName('is-checked');
							
							if(actArrs.length>0){
								let iem=actArrs[0];
								console.log('iem.className',iem.className)
								let str=iem.className.replace(' is-checked','')
								console.log('iem.className',str)
								iem.setAttribute('class','AAA')
							}
							
			
						}
						
//						if(it.className.indexOf('specificDepartment')>-1){
//							console.log('指定部门',);
//						}
//						if(it.className.indexOf('assigner')>-1){
//							console.log('指定人',);
//						}
						
					}
				}
				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let cdtDatas=[];
				this.conditionArrs.map(it=>{
					if(it.keys&&it.val){
						let obj={
							condition:{}
						}
						obj.condition[it.keys]=it.val;
						cdtDatas.push(obj)
					}
				})
				if(cdtDatas.length<1){
					
					this.$message({
						message: '请填写规则条件',
						type: 'warning'
					})
					return;
				}
				let sendObject={};
				if(this.htmlDatas.type=='alarmNotification'){
					sendObject={
						// 告警通知 后台要求的数据格式
						type: this.htmlDatas.type,
						alarmNotification:{
							actionCondition:this.htmlDatas.actionCondition,// 动作触发条件: "1"代表告警次数, "2"代表未恢复
							alarmCount: this.htmlDatas.alarmCount,//校验告警次数的次数
							alarmLevel: this.htmlDatas.alarmLevel,//校验告警次数的告警级别
							alarmTime: this.htmlDatas.alarmTime,//校验告警未恢复的时间,单位分钟
							normalTime:this.htmlDatas.normalTime,//Int 分钟
							notificationRecipient: {
								devicePrincipal: this.htmlDatas.devicePrincipal, //设备负责人
								deviceDepartmentPrincipal: this.htmlDatas.deviceDepartmentPrincipal, //设备部门负责人
								deviceDutyDepartment: this.htmlDatas.deviceDutyDepartment, // 设备责任部门
								assigner: this.htmlDatas.webIsAssigner>0?this.htmlDatas.mulAssigner.join(';'):null,//后台需要的是字符串  显示需要转化格式
							},
							officialAccount: this.htmlDatas.officialAccount,//知方式(公众号) "Y"  "N"
							textMessage: this.htmlDatas.textMessage,// 通知方式(短信) "Y"  "N"
						},
					}
				}
				else if(this.htmlDatas.type=='generateInspection'){
					sendObject={
						// 告警通知 后台要求的数据格式
						type: this.htmlDatas.type,
						generateInspection:{
							actionCondition:this.htmlDatas.actionCondition,// 动作触发条件: "1"代表告警次数, "2"代表未恢复
							alarmCount: this.htmlDatas.alarmCount,//校验告警次数的次数
							alarmLevel: this.htmlDatas.alarmLevel,//校验告警次数的告警级别
							alarmTime: this.htmlDatas.alarmTime,//校验告警未恢复的时间,单位分钟
							normalTime:this.htmlDatas.normalTime,//Int 分钟
							inspectionPlanId:this.htmlDatas.inspectionPlanId,//选择的巡检计划id
						},
					}
				}
				else{
					let keys=this.htmlDatas.receiveType;
					let RecipientObj={}
					if(keys=='specificDepartment'){
						RecipientObj[keys]=this.htmlDatas.specificDepartment;
					}else{
						//assigner
						RecipientObj[keys]=this.htmlDatas.assigner;
					}
					sendObject={
						// 生成工单 后台要求的数据格式
						type: this.htmlDatas.type,
						generateOrder:{
			                actionCondition:this.htmlDatas.actionCondition,
			                alarmCount: this.htmlDatas.alarmCount,//校验告警次数的次数
							alarmLevel: this.htmlDatas.alarmLevel,//校验告警次数的告警级别
							alarmTime: this.htmlDatas.alarmTime,//校验告警未恢复的时间,单位分钟
							normalTime:this.htmlDatas.normalTime,//Int 分钟
			                orderRecipient:RecipientObj,
			                orderLevel:this.htmlDatas.orderLevel,
			                officialAccount: this.htmlDatas.officialAccount,//知方式(公众号) "Y"  "N"
							textMessage: this.htmlDatas.textMessage,// 通知方式(短信) "Y"  "N"
			           	},
					}
				}
				let tiemObj=null;
				
				
				if(this.htmlDatas.isEffect&&this.htmlDatas.activeTime){
					tiemObj={}
					this.htmlDatas.activeTime.map(it=>{
//						tiemObj[it]=this.htmlDatas.startEndTime;
						tiemObj[it]=[this.htmlDatas.startTime,this.htmlDatas.endTime,];
					})
				}
				let isCrossDay=false;
				if(this.htmlDatas.startTime&&this.htmlDatas.endTime){
					let dStr='2022-12-01 ';
					let t1=new Date(dStr+this.htmlDatas.startTime).getTime();
					let t2=new Date(dStr+this.htmlDatas.endTime).getTime();
					if((t1>t2)>=0){
						isCrossDay=true;
					}
				}
				let params={
				    "ruleName":this.htmlDatas.ruleName,
				    "description":this.htmlDatas.description,
				    "ruleType":"order",
				    "ruleStatus":this.htmlDatas.ruleStatus,
				    "ruleConfig":{
				    	conditionGroup:{
				    		logic: this.htmlDatas.logic,
							activeTime:tiemObj,
							isCrossDay:isCrossDay,
							conditions:cdtDatas,
				    	},
				    	actions:[sendObject],
				    },
				}
				
				console.log(JSON.parse(JSON.stringify(params)))
				params.ruleConfig=JSON.stringify(params.ruleConfig);
//				return;
				let reqUrl='create'
				if(this.dataId){
					reqUrl='update';
					params.ruleId=this.dataId;
					params.ruleKey=this.htmlDatas.ruleKey;
				}
				let res = await this.ApiHttp('/order/rule/'+reqUrl,params,'post');
				console.log('点击提交',JSON.parse(JSON.stringify(res)));
				this.$router.go(-1);
			},
			
			cancelSubmit(){
				if(this.isLook){
					this.$router.go(-1);
				}else{
					this.$confirm('取消将无法保存当前配置的规则，确定取消吗？', '提示').then(() => {
						this.$router.go(-1);
					}).catch(() => {});
				}
			},
			validateFroms() {
				let allow = this.$refs.htmlFrom.validate(isOk => { //进行校验
					if (isOk) {
						this.getFromDatas();
					} else {
						this.$message({
							message: '请检查输入是否正确',
							type: 'warning'
						})
					}

				})
			},
			addConditionOptions(){
				this.conditionArrs.push({keys:'',val:null});
			},
			async initProjectArrs() {
				//获取项目列表
				let res = await this.ApiHttp('/base/tenant/project/list')
				if(res&&res.length>0){
					res.map(it=>{
						it.val=String(it.projectId);
						it.name=it.projectName;
					})
					this.checkOptions.projectId=res;
				}
			},
			async initDeviceArrs() {
				//获取设备列表
				let params={
					isloading:false,
				}
				let res = await this.ApiHttp('/base/tenant/device/findAllDevice',params);
				if(res){
					res.map(it=>{
						it.val=String(it.deviceId);
						it.name=it.deviceName;
					})
					this.checkOptions.deviceId=res;
				}
			},
			
			async initDepartment() {
				//获取部门数据 
				let res = await this.ApiHttp('/organization/tenant/dept/findList');
				if(res && res.length > 0) {
					res.map(it => {
						it.name = it.deptName;
						it.val = String(it.deptId);
					})
					this.deptOptions = res; //部门数据
					this.receiveOptions.specificDepartment.options=res;
				}
			},
			async initPeopleList() {
				//获取人员数据
				let res = await this.ApiHttp('/organization/tenant/employee/empList', {isloading: false});
				if(res) {
					res.map(it => {
						it.name = it.userName;
						it.val = String(it.userId);
					})
					this.peopleOptions = res; 
					this.receiveOptions.assigner.options=res;
					
				}
			},
			async initInspectionPlanList() {
				//获取巡检计划数据
				let res = await this.ApiHttp('/inspection/tenant/inspection/plan/findInspectionPlan', {isloading: false});
				if(res) {
					res.map(it => {
						it.name = it.inspectionPlanName;
						it.val = String(it.inspectionPlanId)
					})
					this.insPlanOption = res; 
				}
			},
			

			listenTree(){
				let Arrs=this.$refs.diySelect;
				Arrs.map(it=> it.blur())
			},
			getPosition(proId) {
				getPosition(proId).then((res) => {
					let data = listToTree(res, "positionId", "parentId");
					this.recursivePosition(data);
				});
			},
			recursivePosition(row) {
				let data = row.map((v) => {
					v.label = v.positionName;
					v.id = v.positionId;
					if(v.children != undefined) {
						this.recursivePosition(v.children);
					}
					return v;
				});
				this.checkOptions.positionId=data;
			},
			createFilter(queryString) {
		        return (restaurant) => {
		          	return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
		        };
	      	},
			querySearch(queryString, cb){
		        var restaurants = [
		        	{ "value": '10'},
		        	{ "value": '20'},
		        	{ "value": '30'},
		        	{ "value": '60'},
		        ];
		        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
		        // 调用 callback 返回建议列表的数据
		        cb(results);
			},
		 	getCategoryList() {
		      	getCategoryList().then((response) => {
		        	let data = listToTree(response, "categoryId", "parentId");
		        	this.recursiveCategory(data);
		      	});
		    },
		    //递归系统列表修改属性及数据结构
		    recursiveCategory(row) {
		      let data = row.map((v) => {
		        v.label = v.categoryName;
		        v.id = v.categoryId;
		        if (v.children != undefined) {
		          this.recursiveCategory(v.children);
		        }
		        return v;
		      });
		      this.checkOptions.categoryId=data;
		    },
			
		},

	}
</script>

<style lang="scss" scoped>
	.btmBtn {
		text-align: right;
	}
	.diyRadio.el-radio--medium.is-bordered{
		margin: 6px 0;
		padding: 0 12px;
		padding-bottom: 42px !important;
		
	}
	.minInp{
		width: 120px;
		margin: 8px;
	}
	.mlft12{
		margin-left: 12px;
	}
	.pcikTime{
		/*width: 320px;*/
	}
	.txTip{
		color: #999;
		font-size: 13px;
		i{
			margin-right: 6px;
		}
	}
	.treeWarp{
		margin:0 16px;
		width: 200px;display: inline-block;vertical-align: bottom;
	}
	.effectTime{
		margin-right: 16px;
	}
	.checkboxWarp{
		display: inline-block;
	    vertical-align: middle;	
	}
	.appointInp{
		width: 240px;
		margin-left: 12px;
	}
	.inline{
		display: inline-block;
	}
	
</style>
<style lang="scss">
	
	.ruleConfigForm {
		overflow-y: auto;
		height: calc(100% - 50px);
		.el-input.is-disabled .el-input__inner{
			background-color:#f9f9f9 !important;
		}
		.el-checkbox-button.is-checked .el-checkbox-button__inner{
			background-color: #409EFF !important;
     		border-color: #409EFF !important;
		}
		.el-radio__input.is-checked+.el-radio__label{
			color:#000000 !important;
		}
	}
	.treeWarp{
		.vue-treeselect--disabled{
			.vue-treeselect__single-value{
				color: #c0c4cc;
			}
		}
	}
</style>